// テキストがランダムに出現
var Obj = {
	loop: false,
	minDisplayTime: 2000,// アニメーションの間隔時間
	initialDelay: 400, // アニメーション開始までの遅延時間
	autoStart: true,
	in: {
		effect: 'fadeInUp',//animate.css の中にある採用したい動きのクラス名
		delayScale: 1,// 遅延時間の指数
		delay: 100,// 文字ごとの遅延時間
		sync: false,// アニメーションをすべての文字に同時適用するかどうか
		shuffle: true,// 文字表示がランダムな順に表示されるかどうか
	},
	out: {// 終了時のアニメーション設定をしたい場合はここに追記
	}
}
var element

//初期設定
function RandomInit() {
	element= $(".randomAnime");
	$(element[0]).textillate(Obj);
}

function RandomAnimeControl() {
  var elemPos = $(element[1]).offset().top - 50;
  var scroll = $(window).scrollTop();
  var windowHeight = $(window).height();

  if (scroll >= elemPos - windowHeight) {
    $(element[1]).textillate(Obj);
  }
}

$(window).on('load', function () {
  fadeAnime();
	RandomInit();
	RandomAnimeControl();
});
